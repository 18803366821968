.product {
  &-regions {
    &-exp {
      display: flex;
      align-items: center;
      padding: 14px 16px;
      font-size: 14px;
      text-align: right;
      height: 56px; }
    &-item {
      background-color: #151515;
      color: #fff;
      height: 40px;
      font-size: 13px;
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: center;
      cursor: pointer;
      padding: 0 6px;
      margin-bottom: 40px;
      font-weight: 100; } }
  &-table {
    margin-top: 40px;
    position: relative;
    padding: 40px 32px;
    .table-add-price {
      border-radius: 0;
      width: 100%;
      margin-bottom: 40px;
      color: #151515;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      &-btn {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #151515; }
      &-list {
        position: absolute;
        width: 100%;
        background-color: #fff;
        padding: 0 14px;
        border: 1px solid #151515;
        top: 48px;
        div {
          opacity: .8;
          height: 40px;
          display: flex;
          align-items: center;
          &:hover {
            opacity: 1; } } } }
    &-title {
      padding: 14px 0px;
      div {
        color: #ccc;
        cursor: pointer;
        transition: all .3s;
        &:hover {
          color: #151515; } }
      span {
        font-size: 15px;
        white-space: nowrap; /* Отменяем перенос текста */;
        overflow: hidden; /* Обрезаем содержимое */;
        text-overflow: ellipsis;
        margin-left: 16px;
        transition: all .3s;
        display: block;
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: bold; }
      span.product-link {
        cursor: pointer;
        &:hover {
          color: #0066FF; } } }
    .button-small {
      background-color: #fff;
      border: 1px solid #151515;
      border-radius: 0;
      width: 100%;
      margin-bottom: 40px;
      color: #151515;
      &:hover {
        border: 1px solid #ccc;
        color: #ccc; } }
    &-percent {
      position: absolute;
      top: 24px;
      right: -45px;
      background-color: #151515;
      color: #fff;
      padding: 12px 16px;
      cursor: pointer;
      transition: .3s;
      &:hover {
        opacity: .9; } } }
  &-article {
    font-weight: bold;
    margin-bottom: 32px; }
  &-name {
    font-size: 14px; }
  &-title {
    font-size: 18px;
    div {
      margin-bottom: 8px; } }
  &-content {
    &-border {
      margin-top: 24px;
      border: 1px dashed #ccc; } }
  &-details {
    h2 {
      margin-bottom: 32px; }
    &-btn {
      margin-top: 32px;
      margin-left: auto;
      .button-small {
        border-radius: 2px;
        text-transform: uppercase;
        width: 220px; } }
    &-item {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 14px;
        margin-bottom: 8px; } } } }
.product-link-border {
 }  // border-bottom: 1px dashed #151515;
