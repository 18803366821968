.button {
  width: 343px;
  height: 48px;
  text-transform: uppercase;
  background-color: #1A051D;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 17px;
  font-family: "Proba Pro Bold", sans-serif;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid #1A051D;
  transition: all .3s;
  &:hover {
    opacity: .8; }
  &-left-icon {
    margin-right: 12px; } }
.button-small {
  width: 180px;
  height: 40px;
  font-size: 13px;
  text-transform: capitalize; }
.button-icon {
  width: 48px;
  height: 48px;
  border-radius: 16px; }
