.nav-select {
  position: relative;
  margin-right: 40px;
  &-tab {
    border: 1px solid #cccccc;
    padding: 12px 40px;
    cursor: pointer;
    white-space: nowrap;
    color: #151515;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 240px;
    height: 46px;
    svg {
      margin-left: 16px; } }
  &-menu {
    border: 1px solid #cccccc;
    position: absolute;
    top: 56px;
    width: 100%;
    cursor: pointer;
    color: #D0C9D6;
    z-index: 1;
    background-color: #fff;
    a {
      padding: 12px 40px;
      cursor: pointer;
      color: #151515;
      display: flex;
      align-items: center;
      background-color: #fff;
      width: 100%;
      height: 46px;
      font-size: 16px;
      transition: all .3s;
      font-size: 14px;
      margin: 4px 0;
      text-transform: uppercase; } } }
