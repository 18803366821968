.select {
  font-size: 15px;
  color: #3F3356;
  position: relative;
  &-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;
    cursor: pointer;
    white-space: nowrap;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  &-list {
    overflow: scroll;
    max-height: 300px;
    position: absolute;
    top: 56px;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    color: #D0C9D6;
    z-index: 1;
    div {
      transition: all .3s;
      padding: 8px 0; }
    div:hover {
      color: #3F3356; } } }

@media( max-width: 1440px ) {
  .select {
    font-size: 12px; } }

