.modal {
  background-color: rgba(0,0,0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 12;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  align-items: flex-start;
  padding: 40px 0; }
