.currency-select {
  position: relative;
  &-item {
    border: 1px solid #cccccc;
    padding: 12px 40px;
    cursor: pointer;
    white-space: nowrap;
    color: #151515;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 46px;
    text-transform: uppercase; }
  &-content {
    border: 1px solid #cccccc;
    position: absolute;
    top: 56px;
    width: 100%;
    cursor: pointer;
    z-index: 1;
    background-color: #fff;
    &-item {
      margin-top: 8px;
      padding: 12px 40px;
      cursor: pointer;
      color: #151515;
      display: flex;
      align-items: center;
      background-color: #fff;
      width: 100%;
      height: 46px;
      font-size: 16px;
      -webkit-transition: all .3s;
      transition: all .3s;
      font-size: 14px;
      margin: 4px 0;
      text-transform: uppercase;
      &:hover {
        color: #0066FF; } } } }
