.inner {
  padding: 20px 80px;
  display: flex; }
.costs {
  flex-direction: column;
  position: fixed;
  background: #fff;
  z-index: 1;
  width: 100%;
  top: 78px;
  &-nav {
    display: flex;
    margin-top: 32px;
    background-color: #000;
    padding: 0 80px;
    .selected {
      background-color: #fff;
      color: #151515; }
    a {
      padding: 24px 40px;
      text-transform: uppercase;
      background-color: #151515;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #151515; } } } }
