.modal {
  .form-add {
    position: relative;
    .currency-list-exp {
      position: relative;
      span {
        width: 100%;
        height: 100%;
        padding: 6px; }
      .currency-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        z-index: 3;
        padding: 6px;
        top: 24px;
        span {
          transition: all .3s;
          color: #ccc;
          &:hover {
            color: #151511; } } } }
    .table-content-border {
      margin-top: 40px;
      padding-bottom: 0; }
    .sum-row {
      margin-top: 40px; }
    .form-product-table {
      span {
        padding: 8px; }
      &-edit {
        position: absolute;
        top: -47px;
        right: 10%;
        padding: 16px 24px;
        background: #151515;
        color: #fff; }
      &-number {
        padding: 16px 0;
        overflow: hidden;
        white-space: nowrap; }
      &-input {
        padding: 16px 0;
        input {
          border: 0;
          font-size: 15px;
          text-align: center;
          outline: transparent;
          padding: 0;
          width: 60px; } } } } }
