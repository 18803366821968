.form-add {
  background-color: #fff;
  border-radius: 4px;
  padding: 48px 64px;
  width: 95%;
  h2 {
    font-size: 1rem;
    margin-bottom: 40px;
    margin-top: 20px; }
  .button-small {
    height: 100%;
    text-transform: uppercase; } }
.provision-list {
  input {
    border: none;
    outline: none; } }
