label {
  position: absolute;
  top: -16px;
  font-size: 12px;
  font-weight: bold;
  color: #6f6b6b; }
.input {
  -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 48px;
  position: relative;
  border-radius: 4px;
  input {
    outline: none;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 15px;
    line-height: 20px;
    padding: 17px;
    border-radius: inherit;
    color: #3F3356; }
  .iconInput {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    left: 16px;
    color: #D0C9D6; }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 1.1rem; }
  .iconInputPassword {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    right: 16px;
    color: #D0C9D6; } }
.input.input-icon {
  input {
    padding-left: 40px; } }
.input.input-icon-password {
  input {
    padding-right: 40px; } }

@media( max-width: 1440px ) {
  .input input {
    font-size: 12px; } }
