.price-list {
  .table-item-price {
    padding: 16px 40px;
    text-align: left;
    cursor: pointer; }
  .regions-item {
    padding: 16px 40px; }
  .table .regions:hover {
    color: #000000; }
  .form-add .button {
    width: 100%;
    max-width: 340px;
    font-size: 14px; }
  .regions-item {
    overflow: hidden; } }
