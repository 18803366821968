* {
  box-sizing: border-box;
  font-family: "Proba Pro" ,sans-serif;
}
body {
  background: #fff;
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 100;
}
a {
  color:#1A051D;
  text-decoration: none;
  font-size: 17px;
  transition: all .3s;
}
a:hover {
  color: #0066FF;
}
h1 {
  font-size: 28px;
  font-family: "Proba Pro Bold" ,sans-serif;
  margin-bottom: 32px;
}
h2 {
  font-size: 22px;
  font-family: "Proba Pro SemiBold" ,sans-serif;
  margin-bottom: 24px;
}
h3 {
  font-size: 17px;
  font-family: "Proba Pro SemiBold" ,sans-serif;
  margin-bottom: 16px;
}
h4 {
  font-size: 15px;
  font-family: "Proba Pro Medium" ,sans-serif;
  margin-bottom: 8px;
}
::placeholder {
  color: #D0C9D6;
}
.text-center {
  text-align: center;
}
.inner {
  padding: 20px 80px;
  display: flex;
}
.shadow-box {
  -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
}
.text-right {
  text-align: right;
}
.aroow-container {
  width: 10px;
  height: 10px;
};
.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 10px;
  height: 10px;
};

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  transition: all .3s;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: auto;
  background-color: #868686;
  color: white;
  padding: 12px 16px;
  z-index: 9;
  top: 125%;
  margin-left: -60px;
  height: auto;
  display: flex;
  white-space: normal;
  white-space: initial;
  line-height: 1.4;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media( max-width: 1440px ) {
  body {
    font-size: 12px;
  }
  .input input {
    font-size: 12px;
  }
}