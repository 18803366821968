.orders {
  .table-item-price {
    text-align: left; }
  .form {
    .button {
      width: 100%;
      font-size: 14px; }
    .regions {
      &:hover {
        color: #000; } }
    .regions-item {
      overflow: hidden; } }
  input {
    outline: none;
    border: 0;
    font-size: 14px;
    font-weight: normal; }
  .order-sum {
    margin-right: 40px;
    text-transform: uppercase;
    margin-left: auto;
    color: #6f6b6b;
    display: flex;
    margin-top: 16px; } }
