.nav {
  align-items: center;
  > a {
    font-size: 16px;
    padding: 14px 40px;
    margin: 0 8px;
    border-radius: 2px;
    border: 1px solid #cccccc;
    &:hover {
      border: 1px solid #0066FF; } } }
