.products {
  flex-direction: column;
  &-pannel {
    margin-bottom: 24px;
    .button {
      width: 100%; } } }
.table-content-header {
  border-radius: 4px;
  padding: 32px 0 32px 32px; }
.content-fixed {
  position: fixed;
  top: 78px;
  width: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 80px;
  z-index: 2;
  label {
    display: none; } }
.products.inner {
  position: relative;
  .table-content {
    margin-top: 200px; } }
