.header {
  padding: 16px 80px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 11;
  background: #fff;
  &-search {
    width: 500px; } }
